<template>
    <div id="commande-list">


        <div id="printMeList" v-show="false">
            <div class="invoice-box" style="background-color: white; width:100%">
                <div style="border: 1px solid black;text-align: center ;font-size: large ; margin-bottom: 10px ">
                    <h2> Journal des bons de livraison et des bons de retour</h2>
                </div>
                <div style="text-align: center;font-size: medium">
                    <h3> {{ startDate }} .. {{ endDate }}</h3>
                </div>
                <table border="1" cellpadding="1" cellspacing="0">
                    <tr class="heading">
                        <td class="bordered" style="text-align: center;width:17% ;font-size: medium">
                            Date
                        </td>
                        <td class="bordered" style="text-align: center;width:17% ;font-size: medium">
                            N° BL
                        </td>
                        <td class="bordered" style="text-align: center;width:17% ;font-size: medium">
                            Total H.T
                        </td>
                        <td class="bordered" style="text-align: center;width:17% ;font-size: medium">
                            Total T.T.C
                        </td>
                        <td class="bordered" style="text-align: center;width:17% ;font-size: medium">
                            Total TVA
                        </td>
                        <td class="bordered" style="text-align: center;width:15% ;font-size: medium">
                            M/P
                        </td>
                    </tr>

                    <tr class="item" :key="indextr" v-for="(tr, indextr) in reversedRows">
                        <td class="bordered"
                            style="text-align: center;width:17%;font-size: medium;padding-bottom: 5px;padding-top: 5px">
                            {{ tr.Posting_Date.substring(0, 10) }}
                        </td>
                        <td class="bordered"
                            style="text-align: center;width:17%;font-size: medium;padding-bottom: 5px;padding-top: 5px">
                            {{ tr.No }}
                        </td>
                        <td class="bordered"
                            style="text-align: center;width:17%;font-size: medium;padding-bottom: 5px;padding-top: 5px">
                            {{ parseFloat(tr.Amount).toFixed(3).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') }}
                        </td>
                        <td class="bordered"
                            style="text-align: center;width:17%;font-size: medium;padding-bottom: 5px;padding-top: 5px">
                            {{
                                parseFloat(tr.Amount_Including_VAT).toFixed(3).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                            }}
                        </td>
                        <td class="bordered"
                            style="text-align: center;width:17%;font-size: medium;padding-bottom: 5px;padding-top: 5px">
                            {{ parseFloat(parseFloat(tr.Amount_Including_VAT) -
                                parseFloat(tr.Amount)).toFixed(3).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                            }}
                        </td>
                        <td class="bordered"
                            style="text-align: center;width:15%;font-size: medium;padding-bottom: 5px;padding-top: 5px">
                            {{ tr.Gen_Prod_Posting_Group }}
                        </td>
                    </tr>

                </table>

                <br>

                <table>
                    <tr>
                        <td class="bordered" style="text-align: center;width:34% ;font-size: large;">
                            <strong>TOTAL : </strong>
                        </td>
                        <td class="bordered" style="width:17% ;font-size: large; text-align: center"
                            v-if="agGrid.rows != null">
                            <strong>{{ getTotalBLHT().toFixed(3).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') }}</strong>
                        </td>
                        <td class="bordered" style="width:17% ;font-size: large; text-align: center"
                            v-if="agGrid.rows != null">
                            <strong>{{ getTotalBLTTC().toFixed(3).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') }}</strong>
                        </td>
                        <td class="bordered" style="width:17% ;font-size: large; text-align: center"
                            v-if="agGrid.rows != null">
                            <strong>{{ getTotalBLTVA().toFixed(3).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') }}</strong>
                        </td>
                        <td style="text-align: right;width:15% ;font-size: large; text-align: center">
                        </td>

                    </tr>
                </table>
            </div>
        </div>


        <b-modal size="lg" hide-footer ref="claim-modal" title="Nouvelle Réclamation">
            <b-row>
                <b-col cols="12" md="12">
                    <b-form-group>
                        <label style="font-weight:bold">Réclamation : </label>
                        <b-form-textarea id="textarea-state" ref="description" v-model="recText"
                            :state="recText.length <= 400" placeholder="Ne pas dépasser 400 caractères" rows="3" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="text-right">

                <b-col cols="12" md="12">
                    <b-button @click="sendClaim" variant="gradient-success">
                        Réclamer
                    </b-button>
                </b-col>

            </b-row>

        </b-modal>


        <b-modal ref="my-modal" scrollable title="Détail bon de livraison" ok-only ok-title="Annuler" size="xl"
            cancel-variant="outline-secondary">

            <b-table :bordered="true" :small="true" :hover="true" :no-border-collapse="true" :items="commandesSelected"
                :fields="fields" />

        </b-modal>
        <b-overlay :show="showLoading" spinner-variant="primary">
            <b-card>

                <ag-grid :withFilters="true" :filterComponent="ShipmentsFilter" :agGrid="agGrid"
                    pageTitle="Liste des bons de livraison" @refresh="loadData" @filterClicked="filterClicked"
                    @printClicked="printClicked" />

            </b-card>
        </b-overlay>

    </div>
</template>

<script>
import AgGrid from "@/views/components/ag-grid/AgGrid.vue"
import ButtonCellRenderer from "@/views/components/ag-grid/ButtonCellRenderer.vue"
import BadgeCellRenderer from "@/views/components/ag-grid/BadgeCellRenderer.vue"
import ShipmentsFilter from './ShipmentsFilter.vue';
import { formatPrice } from "@/utils/formatting";
import printDocs from "@/utils/printDocs";

export default {
    components: {
        // VueAutosuggest,
        AgGrid
    },
    data() {
        return {
            recText: "",
            reversedRows: [],
            ShipmentsFilter: ShipmentsFilter,
            startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 2).toISOString().split('T')[0],
            endDate: new Date().toISOString().split('T')[0],
            agGrid: {
                columnDefs: [
                    // {
                    //     headerName: "N° Commande",
                    //     field: "Order_No",
                    // },
                    {
                        headerName: "Date Livraison",
                        field: "Posting_Date",
                        valueGetter: function (tr) {
                            return tr.data.Posting_Date.substring(0, 10);
                        },
                        //cellClass: 'right-customeCellRender',
                        //headerClass: 'right-customeHeaderRender',
                    },
                    {
                        headerName: "N° BL",
                        field: "No",
                    },
                    {
                        headerName: "Total H.T",
                        field: "Amount",
                        valueGetter: function (tr) {
                            return formatPrice(tr.data.Amount);
                        },
                        //cellClass: 'right-customeCellRender',
                        //headerClass: 'right-customeHeaderRender',
                    },
                    {
                        headerName: "Total T.T.C",
                        field: "Amount_Including_VAT",
                        valueGetter: function (tr) {
                            return formatPrice(tr.data.Amount_Including_VAT);
                        },
                        //cellClass: 'right-customeCellRender',
                        //headerClass: 'right-customeHeaderRender',
                    },
                    {
                        headerName: "Type",
                        field: "Gen_Prod_Posting_Group",
                        cellRendererFramework: 'badgeCellRenderer',
                        cellRendererParams: { componentName: "Shipments", columnName: "Gen_Prod_Posting_Group" },
                    },
                    {
                        sortable: false,
                        resizable: false,
                        filter: false,
                        headerName: '',
                        cellClass: 'centered-customeCellRender',
                        cellRendererFramework: 'buttonCellRenderer',
                        cellRendererParams: { onButtonClick: this.claim, icon: "contact_support", tooltip: "Réclamer sur ce BL" },
                        width: 100
                    },
                    {
                        sortable: false,
                        resizable: false,
                        filter: false,
                        headerName: '',
                        cellClass: 'centered-customeCellRender',
                        cellRendererFramework: 'buttonCellRenderer',
                        cellRendererParams: { onButtonClick: this.showDetails, icon: "read_more", tooltip: "voir les détails" },
                        width: 100
                    },
                    {
                        sortable: false,
                        resizable: false,
                        filter: false,
                        headerName: '',
                        cellClass: 'centered-customeCellRender',
                        cellRendererFramework: 'buttonCellRenderer',
                        cellRendererParams: { onButtonClick: this.printDoc, icon: "picture_as_pdf", tooltip: "Imprimer" },
                        width: 100
                    },
                ],
                rows: []
            },
            fields: [{
                label: 'Code Article',
                key: 'No',
                sortable: true
            },
            {
                label: 'Désignation article',
                key: 'Description',
                sortable: true
            },
            {
                label: 'Prix',
                key: 'Unit_Price',
                sortable: true
            }, {
                label: 'Quantité',
                key: 'Quantity',
                sortable: true
            }
            ],
            commandesSelected: [],
            showLoading: true,
            selectedType: "Tous"
        }
    },
    created() {
        this.$options.components.buttonCellRenderer = ButtonCellRenderer;
        this.$options.components.badgeCellRenderer = BadgeCellRenderer;
        this.loadData();
    },
    methods: {
        async sendClaim() {
            this.$refs['claim-modal'].hide();
            this.showLoading = true;
            let inputs = {
                // "Date": dateString,
                "Wording": this.recText.substring(0, 400),
                "Web_Complaint": true
            }
            let response = await this.$http.post("claim", inputs).catch(() => {
                this.showLoading = false
            });
            if (response.status == 200) {
                this.recText = '';
                this.$swal({
                    title: 'Réclamation envoyée avec succès .',
                    icon: 'success',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    timer: 4000,
                    buttonsStyling: false,
                });
            }
            this.showLoading = false;
        },
        claim(data) {
            this.$refs["claim-modal"].show();
            this.recText = "Cette réclamation est à l'ordre du BL n° : '" + data.No + "' : ";
            setTimeout(() => {
                this.$refs["description"].focus();
            }, 400);
        },
        printClicked() {
            this.reversedRows = [...this.agGrid.rows];
            this.reversedRows = this.reversedRows.reverse();
            this.$nextTick(function () {
                var contents = document.getElementById("printMeList").innerHTML;
                printDocs.print(contents);
            });
        },
        getTotalBLHT() {
            let total = 0;
            this.reversedRows.forEach(el => total = parseFloat(total) + parseFloat(el.Amount))
            return total;
        },
        getTotalBLTTC() {
            let total = 0;
            this.reversedRows.forEach(el => total = parseFloat(total) + parseFloat(el.Amount_Including_VAT))
            return total;
        },
        getTotalBLTVA() {
            return this.getTotalBLTTC() - this.getTotalBLHT();
        },
        async printDoc(event) {
            this.showLoading = true;
            await this.$http.put("shipment_header", { Print: true }, { params: { No: "'" + event.No + "'" } }).catch(() => this.showLoading = false);
            let response = await this.$http.get("file/document/" + event.No.replace(/:/g, "") + ".pdf?type=bl", { responseType: "blob" }).catch(() => this.showLoading = false);
            this.showLoading = false;
            const fileURL = URL.createObjectURL(response.data);
            const a = document.createElement("a");
            a.href = fileURL;
            a.target = "_blank";
            a.rel = "noopener noreferrer";
            a.click();
            URL.revokeObjectURL(fileURL);
        },
        filterClicked(obj) {
            this.startDate = obj.sDate;
            this.endDate = obj.eDate;
            this.selectedType = obj.type;
            this.loadData();
        },
        async showDetails(event) {
            this.showLoading = !this.showLoading;
            let response = await this.$http.get("shipment_line/findWhere", {
                params: {
                    Document_No: "'" + event.No + "'"
                }
            }).catch(() => this.showLoading = false);
            this.commandesSelected = response.data;
            this.showLoading = !this.showLoading;
            this.$refs['my-modal'].show();
        },
        async loadData() {
            this.showLoading = true;
            let response = await this.$http.post("shipment_header/findWhere", this.generateDateFilters());
            this.agGrid.rows = [...response.data].reverse();
            this.agGrid.rows = this.agGrid.rows.filter(x => x.No.substring(0, 2) != 'RV')
            this.showLoading = false;
            // axios.post("shipment_header/findWhere", this.generateDateFilters())
            //     .then(response => {
            //         this.agGrid.rows = [...response.data].reverse();
            //         this.agGrid.rows = this.agGrid.rows.filter(x => x.No.substring(0, 2) != 'RV')
            //         this.show = false;
            //     });
        },
        generateDateFilters() {
            const filters = [
                {
                    "field": "Posting_Date",
                    "value": "",
                    "operator": "gt"
                },
                {
                    "field": "Posting_Date",
                    "value": "",
                    "operator": "lt"
                }
            ];
            // Convert start date string to Date and adjust by -1 day
            const startDateObj = new Date(this.startDate);
            startDateObj.setDate(startDateObj.getDate() - 1);
            // Convert end date string to Date and adjust by +1 day
            const endDateObj = new Date(this.endDate);
            endDateObj.setDate(endDateObj.getDate() + 1);
            // Format adjusted dates as strings in yyyy-MM-dd format
            const formattedStartDate = startDateObj.toISOString().split('T')[0];
            const formattedEndDate = endDateObj.toISOString().split('T')[0];
            // Set adjusted date values in the filter objects
            filters[0].value = formattedStartDate;
            filters[1].value = formattedEndDate;
            if (this.selectedType != "Tous")
                filters.push({
                    "field": "Gen_Prod_Posting_Group",
                    "value": "'" + this.selectedType + "'",
                    "operator": "eq"
                });
            return filters;
        }
    }
}
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
    .card {
        background-color: initial;
        box-shadow: initial;
    }

    .card-body {
        padding: 0px;
    }
}
</style>